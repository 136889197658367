import { createApp } from 'vue'
import Vant from 'vant'
import App from './App.vue'
import router from './router'
import store from './store'
import '@vant/touch-emulator'

import '@/assets/js/rem.js'

import 'vant/lib/index.css'
import '@/assets/css/reset.less'
import '@/assets/icons/iconfont.css'

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

createApp(App).use(store).use(router).use(Vant)
  .mount('#app')
