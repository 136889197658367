import { createRouter, createWebHistory } from 'vue-router'
import { defineAsyncComponent } from 'vue'
const Main = defineAsyncComponent(() => import('_c/common/common'))
const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '引导页-开发'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "home" */ '../views/index.vue'))
  },
  {
    path: '/water_application/:appId/:openId',
    name: 'water_application',
    meta: {
      title: '用户报装'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "water-application" */ '../views/water-application.vue'))
  },
  {
    path: '/account_name_change/:appId/:openId',
    name: 'account_name_change',
    meta: {
      title: '户名变更'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "account-name-change" */ '../views/account-name-change.vue'))
  },
  {
    path: '/account_name_change_hlgl/:appId/:openId',
    name: 'account_name_change_hlgl',
    meta: {
      title: '户名变更'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "account-name-change" */ '../views/account-name-change-hlgl.vue'))
  },
  {
    path: '/new_account_name_change/:appId/:openId',
    name: 'new_account_name_change',
    meta: {
      title: '户名变更'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "account-name-change" */ '../views/new-account-name-change.vue'))
  },
  {
    path: '/account_name_change_db/:appId/:openId',
    name: 'account_name_change_db',
    meta: {
      title: '户名变更'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "account-name-change" */ '../views/account-name-change-db.vue'))
  },
  {
    path: '/account_name_change_hs_enter/:appId/:openId',
    name: 'account_name_change_hs_enter',
    meta: {
      title: '户名变更'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "account-name-change" */ '../views/account-name-change-hs-enter.vue'))
  },
  {
    path: '/account_name_change_qth/:appId/:openId',
    name: 'account_name_change_qth',
    meta: {
      title: '户名变更'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "account-name-change-qth" */ '../views/account-name-change-qth.vue'))
  },
  {
    path: '/user_info/:appId/:openId',
    name: 'user_info',
    meta: {
      title: '用户信息'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "user-info" */ '../views/user-info.vue'))
  },
  {
    path: '/user_info_new/:appId/:openId',
    name: 'user_info_new',
    meta: {
      title: '用户信息'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "user-info" */ '../views/user-info-new.vue'))
  },
  {
    path: '/user_bind/:appId/:openId/:checkFirst',
    name: 'user_bind',
    meta: {
      title: '用户绑定'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "user-bind" */ '../views/user-bind.vue'))
  },
  {
    path: '/user_bind_new/:appId/:openId/:checkFirst',
    name: 'user_bind_new',
    meta: {
      title: '用户绑定'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "user-bind" */ '../views/user-bind-new.vue'))
  },
  {
    path: '/water_bill/:appId/:openId',
    name: 'water_bill',
    meta: {
      title: '水费账单'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "water-bill" */ '../views/water-bill.vue'))
  },
  {
    path: '/bill_payment/:appId/:openId/:customerId',
    name: 'bill_payment',
    meta: {
      title: '账单缴费'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "bill-payment" */ '../views/bill-payment.vue'))
  },
  {
    path: '/bill_payment_user/:appId/:openId',
    name: 'bill_payment_user',
    meta: {
      title: '账单缴费'
    },
    component: () => import(/* webpackChunkName: "bill-payment" */ '../views/bill-payment-user.vue')
  },
  {
    path: '/bill_payment_pay/:appId/:openId',
    name: 'bill_payment_pay',
    meta: {
      title: '账单缴费'
    },
    component: () => import(/* webpackChunkName: "bill-payment" */ '../views/bill-payment-pay.vue')
  },
  {
    path: '/bill_payment_new/:appId/:openId/:customerId',
    name: 'bill_payment_new',
    meta: {
      title: '账单缴费'
    },
    component: () => import(/* webpackChunkName: "bill-payment" */ '../views/bill-payment-new.vue')
  },
  {
    path: '/service_guide',
    name: 'service_guide',
    meta: {
      title: '操作指引'
    },
    component: Main,
    children: [
      {
        path: '/service_guide',
        name: '_service_guide',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "service-guide" */ '../views/service-guide/index.vue'))
      },
      {
        path: 'bind',
        name: 'bind',
        meta: {
          title: '公众号绑定指引'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "bind" */ '../views/service-guide/bind.vue'))
      },
      {
        path: 'payment',
        name: 'payment',
        meta: {
          title: '充值缴费指引'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "payment" */ '../views/service-guide/payment.vue'))
      },
      {
        path: 'query',
        name: 'query',
        meta: {
          title: '账单查询指引'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "query" */ '../views/service-guide/query.vue'))
      },
      {
        path: 'transaction_failed',
        name: 'transaction_failed',
        meta: {
          title: '异常交易订单指引'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "transaction-failed" */ '../views/service-guide/transaction-failed.vue'))
      },
      {
        path: '/service_guide/list/:appId',
        name: 'service_guide_list',
        meta: {
          title: '服务指南'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "service-guide-list" */ '../views/service-guide/list.vue'))
      },
      {
        path: '/service_guide/detail/:appId/:infoId',
        name: 'service_guide_detail',
        meta: {
          title: '服务指南详情'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "service-guide-detail" */ '../views/infos/detail.vue'))
      }
    ]
  },
  {
    path: '/business_outlets/:appId/:openId',
    name: 'business_outlets',
    meta: {
      title: '营业网点'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "business-outlets" */ '../views/business-outlets.vue'))
  },
  {
    path: '/online_recharge/:appId/:openId/:customerId',
    name: 'online_recharge',
    meta: {
      title: '在线充值'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "online-recharge" */ '../views/online-recharge.vue'))
  },
  {
    path: '/online_recharge_pay/:appId/:openId/:customerId',
    name: 'online_recharge_pay',
    meta: {
      title: '在线充值'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "online-recharge" */ '../views/online-recharge-pay.vue'))
  },
  {
    path: '/meal_recharge/:appId/:openId/:customerId',
    name: 'meal_recharge',
    meta: {
      title: '套餐充值'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "meal-recharge" */ '../views/meal-recharge.vue'))
  },
  {
    path: '/invoice_link/:appId/:openId/:customerId',
    name: 'invoice_link',
    meta: {
      title: '电子发票'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "invoice-link" */ '../views/invoice-link.vue'))
  },
  {
    path: '/invoice_link_huangyuan/:appId/:openId/:customerId',
    name: 'invoice_link_huangyuan',
    meta: {
      title: '电子发票'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "invoice-link-huangyuan" */ '../views/invoice-link-huangyuan.vue'))
  },
  {
    path: '/invoice_detail/:appId/:openId/:customerId/:chargeId',
    name: 'invoice_detail',
    meta: {
      title: '发票链接'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "invoice-detail" */ '../views/invoice-detail.vue'))
  },
  {
    path: '/invoice_detail_huangyuan/:appId/:openId/:customerId/:chargeDate/:index',
    name: 'invoice_detail_huangyuan',
    meta: {
      title: '发票链接'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "invoice-detail-huangyuan" */ '../views/invoice-detail-huangyuan.vue'))
  },
  {
    path: '/digital_invoice/:appId/:openId/:customerId',
    name: 'digital_invoice',
    meta: {
      title: '发票链接'
    },
    component: () => import(/* webpackChunkName: "invoice-link" */ '../views/digital-invoice.vue')
  },
  {
    path: '/electronic_link/:appId/:openId/:customerId',
    name: 'electronic_link',
    meta: {
      title: '电子发票'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "invoice-link" */ '../views/electronic-link.vue'))
  },
  {
    path: '/electronic_detail/:appId/:openId/:customerId/:chargeId/:collectMoney',
    name: 'electronic_detail',
    meta: {
      title: '自主开票'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "invoice-detail" */ '../views/electronic-detail.vue'))
  },
  {
    path: '/payment_record/:appId/:openId/:customerId',
    name: 'payment_record',
    meta: {
      title: '缴费记录'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "payment-record" */ '../views/payment-record.vue'))
  },
  {
    path: '/bill_payment_again/:appId/:openId/:tradeno',
    name: 'bill_payment_again',
    meta: {
      title: '异常交易订单处理'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "bill-payment-again" */ '../views/bill-payment-again.vue'))
  },
  {
    path: '/palm_business',
    name: 'palm-business',
    meta: {
      title: '掌上营业厅'
    },
    component: Main,
    children: [
      // {
      //   path: '/palm_business/:appId/:openId',
      //   name: 'palm_business',
      //   component: defineAsyncComponent(() => import(/* webpackChunkName: "palm_business" */ '../views/palm-business.vue'))
      // },
      // {
      //   path: '/palm_business_hall/:appId/:openId',
      //   name: 'palm_business_hall',
      //   component: defineAsyncComponent(() => import(/* webpackChunkName: "palm_business" */ '../views/palm-business/index.vue'))
      // },
      {
        path: 'account_statistics/:appId/:openId/:customerId',
        name: 'account_statistics',
        meta: {
          title: '账户统计'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "palm_business" */ '../views/palm-business/account-statistics.vue'))
      },
      {
        path: 'apply',
        name: 'apply',
        meta: {
          title: '申请'
        },
        component: Main,
        children: [
          {
            path: 'account_cancellation/:appId/:openId/:customerId',
            name: 'account_cancellation',
            meta: {
              title: '销户申请'
            },
            component: defineAsyncComponent(() => import(/* webpackChunkName: "apply" */ '../views/palm-business/apply/account-cancellation.vue'))
          },
          {
            path: 'new_account_cancellation/:appId/:openId/:customerId',
            name: 'new_account_cancellation',
            meta: {
              title: '销户申请'
            },
            component: defineAsyncComponent(() => import(/* webpackChunkName: "apply" */ '../views/palm-business/apply/new-account-cancellation.vue'))
          },
          {
            path: 'account_cancellation_hlgl/:appId/:openId/:customerId',
            name: 'account_cancellation_hlgl',
            meta: {
              title: '销户申请'
            },
            component: defineAsyncComponent(() => import(/* webpackChunkName: "apply" */ '../views/palm-business/apply/account-cancellation-hlgl.vue'))
          },
          {
            path: 'declare_stop/:appId/:openId/:customerId',
            name: 'declare_stop',
            meta: {
              title: '报停申请'
            },
            component: defineAsyncComponent(() => import(/* webpackChunkName: "apply" */ '../views/palm-business/apply/declare-stop.vue'))
          },
          {
            path: 'new_declare_stop/:appId/:openId/:customerId',
            name: 'new_declare_stop',
            meta: {
              title: '报停申请'
            },
            component: defineAsyncComponent(() => import(/* webpackChunkName: "apply" */ '../views/palm-business/apply/new-declare-stop.vue'))
          },
          {
            path: 'declare_stop_hlgl/:appId/:openId/:customerId',
            name: 'declare_stop_hlgl',
            meta: {
              title: '报停申请'
            },
            component: defineAsyncComponent(() => import(/* webpackChunkName: "apply" */ '../views/palm-business/apply/declare-stop-hlgl.vue'))
          },
          {
            path: 'reinstall/:appId/:openId/:customerId',
            name: 'reinstall',
            meta: {
              title: '复装申请'
            },
            component: defineAsyncComponent(() => import(/* webpackChunkName: "apply" */ '../views/palm-business/apply/reinstall.vue'))
          },
          {
            path: 'change_table/:appId/:openId/:customerId',
            name: 'change_table',
            meta: {
              title: '换表申请'
            },
            component: defineAsyncComponent(() => import(/* webpackChunkName: "apply" */ '../views/palm-business/apply/change-table.vue'))
          },
          {
            path: 'new_change_table/:appId/:openId/:customerId',
            name: 'new_change_table',
            meta: {
              title: '换表申请'
            },
            component: defineAsyncComponent(() => import(/* webpackChunkName: "apply" */ '../views/palm-business/apply/new-change-table.vue'))
          },
          {
            path: 'repair/:appId/:openId/:customerId',
            name: 'repair',
            meta: {
              title: '报修申请'
            },
            component: defineAsyncComponent(() => import(/* webpackChunkName: "apply" */ '../views/palm-business/apply/repair.vue'))
          },
          {
            path: 'repair_hlgl/:appId/:openId/:customerId',
            name: 'repair_hlgl',
            meta: {
              title: '报修申请'
            },
            component: defineAsyncComponent(() => import(/* webpackChunkName: "apply" */ '../views/palm-business/apply/repair-hlgl.vue'))
          }
        ]
      },
      {
        path: 'water_nature_change/:appId/:openId/:customerId',
        name: 'water_nature_change',
        meta: {
          title: '用水性质变更'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "palm_business" */ '../views/palm-business/water-nature-change.vue'))
      },
      {
        path: 'new_water_nature_change/:appId/:openId/:customerId',
        name: 'new_water_nature_change',
        meta: {
          title: '用水性质变更'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "palm_business" */ '../views/palm-business/new-water-nature-change.vue'))
      },
      {
        path: 'water_nature_change_hlgl/:appId/:openId/:customerId',
        name: 'water_nature_change_hlgl',
        meta: {
          title: '用水性质变更'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "palm_business" */ '../views/palm-business/water-nature-change-hlgl.vue'))
      },
      {
        path: 'business_inquiry/:appId/:openId/:customerId',
        name: 'business_inquiry',
        meta: {
          title: '业务办理查询'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "palm_business" */ '../views/palm-business/business-inquiry/list.vue'))
      },
      {
        path: 'business_inquiry_detail/:appId/:openId/:flowType/:itemId',
        name: 'business_inquiry_detail',
        meta: {
          title: '业务办理'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "palm_business" */ '../views/palm-business/business-inquiry/detail.vue'))
      },
      {
        path: 'business_charges/:appId/:openId',
        name: 'business_charges',
        meta: {
          title: '业务缴费'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "palm_business" */ '../views/palm-business/business-charges.vue'))
      },
      {
        path: 'water_pack/:appId/:openId',
        name: 'water_pack',
        meta: {
          title: '用水报装'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "palm_business" */ '../views/palm-business/water-pack.vue'))
      },
      {
        path: 'water_pack_ch/:appId/:openId',
        name: 'water_pack_ch',
        meta: {
          title: '用水报装'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "palm_business" */ '../views/palm-business/water-pack-ch.vue'))
      },
      {
        path: 'complaints/:appId/:openId',
        name: 'complaints',
        meta: {
          title: '投诉举报'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "palm_business" */ '../views/palm-business/complaints.vue'))
      },
      {
        path: 'material_supplement/:appId/:flowType/:itemId',
        name: 'material_supplement',
        meta: {
          title: '材料补充'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "palm_business" */ '../views/palm-business/material-supplement.vue'))
      },
      {
        path: 'remote_meter_manage/:appId/:openId/:customerId',
        name: 'remote_meter_manage',
        meta: {
          title: '远传表管理'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "apply" */ '../views/palm-business/remote-meter-manage.vue'))
      },
      {
        path: 'water_consumption_statistics/:appId/:openId/:customerId',
        name: 'water_consumption_statistics',
        meta: {
          title: '用水量统计'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "palm_business" */ '../views/palm-business/water-consumption-statistics.vue'))
      }
    ]
  },
  {
    path: '/business_hall/:appId/:openId',
    name: 'business_hall',
    meta: {
      title: '掌上营业厅'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "business_hall" */ '../views/business-hall.vue'))
  },
  {
    path: '/business_hall_dingbian/:appId/:openId',
    name: 'business_hall_dingbian',
    meta: {
      title: '掌上营业厅'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "business_hall" */ '../views/business-hall.vue'))
  },
  {
    path: '/infos',
    name: 'infos',
    meta: {
      title: '资讯消息'
    },
    component: Main,
    children: [
      {
        path: '/infos/:appId',
        name: '_infos',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "service-guide" */ '../views/infos/list.vue'))
      },
      {
        path: 'detail/:appId/:infoId',
        name: 'infos_detail',
        meta: {
          title: '资讯消息'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "bind" */ '../views/infos/detail.vue'))
      }
    ]
  },
  {
    path: '/notices',
    name: 'notices',
    meta: {
      title: '停水通知'
    },
    component: Main,
    children: [
      {
        path: '/notices/:appId',
        name: '_notices',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "service-guide" */ '../views/notices/list.vue'))
      },
      {
        path: 'detail/:appId/:stopId',
        name: 'notices_detail',
        meta: {
          title: '停水通知'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "bind" */ '../views/notices/detail.vue'))
      }
    ]
  },
  {
    path: '/announcement',
    name: 'announcement',
    meta: {
      title: '水质公告'
    },
    component: Main,
    children: [
      {
        path: '/announcement/:appId',
        name: '_announcement',
        component: defineAsyncComponent(() => import(/* webpackChunkName: "service-guide" */ '../views/announcement/list.vue'))
      },
      {
        path: 'qth/:appId',
        name: 'qth',
        meta: {
          title: '水质信息'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "service-guide" */ '../views/announcement/list-qth.vue'))
      },
      {
        path: 'detail/:appId/:infoId',
        name: 'announcement_detail',
        meta: {
          title: '水质详情'
        },
        component: defineAsyncComponent(() => import(/* webpackChunkName: "bind" */ '../views/announcement/detail.vue'))
      }
    ]
  },
  {
    path: '/esign',
    name: 'esign',
    meta: {
      title: '在线签名'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "esign" */ '../views/esign.vue'))
  },
  {
    path: '/water_application_registration',
    name: 'water_application_registration',
    meta: {
      title: '用水报装登记表'
    },
    component: Main,
    children: [
      {
        path: '/water_application_registration/:appId/:openId',
        name: '_water_application_registration',
        component: () => import(/* webpackChunkName: "water-application-heihe" */ '../views/water-application/registration.vue')
      },
      {
        path: 'record/:appId',
        name: 'record',
        meta: {
          title: '申请记录查询'
        },
        component: () => import(/* webpackChunkName: "water-application-heihe" */ '../views/water-application/record.vue')
      }
    ]
  },
  {
    path: '/pdf',
    name: 'pdf',
    meta: {
      title: 'pdf'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "esign" */ '../views/pdf.vue'))
  },
  {
    path: '/big_meter_info/:appId',
    name: 'big_meter_info',
    meta: {
      title: '大表信息查询'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "big_meter_info" */ '../views/business/big-meter-info.vue'))
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: {
      title: '页面不存在'
    },
    component: defineAsyncComponent(() => import(/* webpackChunkName: "error" */ '../views/404.vue'))
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
